import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";

function PrivateRouteForNonAuth({ children }) {
  const { user } = useUserAuth();
  const [showOutlet, setShowOutlet] = useState(false);

  useEffect(() => {
    const delay = 400; // Adjust the delay time as needed
    const timer = setTimeout(() => {
      setShowOutlet(true);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  if (user) {
    return <Navigate to="/profile" />; // Redirect to profile if authenticated
  }

  return showOutlet ? <Outlet /> : null;
}

export default PrivateRouteForNonAuth;
