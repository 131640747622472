import { Outlet, Navigate } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";

function PrivateRouteForAuth({ children }) {
  const { getUser } = useUserAuth();

  const user = getUser();

  if (!user) {
    return <Navigate to="/login" replace />; // Redirect to login if not authenticated
  }

  return <Outlet />;
}

export default PrivateRouteForAuth;
