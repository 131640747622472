import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Register from "./pages/Register";
import NotFound from "./pages/NotFound";
import PrivateRouteForAuth from "./utils/PrivateRouteForAuth";
import PrivateRouteForNonAuth from "./utils/PrivateRouteForNonAuth";
import {
  UserAuthContextProvider,
  useUserAuth,
} from "./context/userAuthContext"; // Import useUserAuth
import ForgotPassword from "./pages/ForgotPassword";

function App() {
  return (
    <UserAuthContextProvider>
      <AppRouter />
    </UserAuthContextProvider>
  );
}

function AppRouter() {
  const { user } = useUserAuth(); // Get the user from the context

  return (
    <Router>
      <div className="container">
        <Routes>
          {/* Redirect root URL to Profile if user is authenticated */}
          <Route
            path="/"
            element={
              user ? (
                <Navigate to="/profile" />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route element={<PrivateRouteForAuth />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route element={<PrivateRouteForNonAuth />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
